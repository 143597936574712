import { fetchFiredEmployeesStatistics, fetchHiredEmployeesStatistics } from 'Redux/actions/statistics';
import { store } from '../../../index';
import { endOfYear, format, startOfYear } from 'date-fns';

export function* onDeleteUserRecord({ payload }) {
  const { dispatch } = store;
  const startDate = format(startOfYear(new Date(payload.yearFilter)), 'yyyy-MM-dd');
  const endDate = format(endOfYear(new Date(payload.yearFilter)), 'yyyy-MM-dd');
  dispatch(fetchHiredEmployeesStatistics(startDate, endDate));
  dispatch(fetchFiredEmployeesStatistics(startDate, endDate));
  yield true;
}
