import { taskStatuses } from '../../../../assets/projectTaskStatus';
import { fetchAllBacklogTasks, fetchAllProjectTasks } from '../../../actions/projectTasks';
import { store } from '../../../index';

function* onCreateProjectTask(action) {
  const { dispatch, getState } = store;
  const { projectId, status } = action.payload;
  const projectTasksFilters = getState().filters.projectFilters.board;

  status === taskStatuses.BACKLOG
    ? dispatch(fetchAllBacklogTasks(projectId))
    : dispatch(fetchAllProjectTasks(projectId, { ...projectTasksFilters }));

  yield true;
}

export default onCreateProjectTask;
