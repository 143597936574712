import i18next from 'i18next';

export const roles = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  RECRUITER: 'RECRUITER',
  HR: 'HR',
  SALES: 'SALES',
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
  PARTNER: 'PARTNER',
  CANDIDATE: 'CANDIDATE',
  ACCOUNTANT: 'ACCOUNTANT',
};
export const additionRole = {
  LEAD: 'LEAD',
};
export const additionRoleArray = ['LEAD'];

export const additionalRolesFilterLabel = () => ({
  LEAD: i18next.t('leadRole'),
});

export const rolesForCandidate = [
  'MANAGER',
  'RECRUITER',
  'HR',
  'SALES',
  'EMPLOYEE',
  'CONTRACTOR',
  'PARTNER',
  'ACCOUNTANT',
];

export const rolesTeam = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  RECRUITER: 'RECRUITER',
  HR: 'HR',
  SALES: 'SALES',
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
  PARTNER: 'PARTNER',
  ACCOUNTANT: 'ACCOUNTANT',
};

export const rolesTeamLabel = () => ({
  OWNER: i18next.t('ownerRole'),
  ADMIN: i18next.t('adminRole'),
  MANAGER: i18next.t('managerRole'),
  RECRUITER: i18next.t('recruiterRole'),
  HR: i18next.t('HRRole'),
  SALES: i18next.t('salesRole'),
  EMPLOYEE: i18next.t('employeeRole'),
  CONTRACTOR: i18next.t('contractorRole'),
  PARTNER: i18next.t('partnerRole'),
  ACCOUNTANT: i18next.t('accountantRole'),
  roleArray: i18next.t('roleArray'),
});

export const rolesLabel = () => ({
  OWNER: i18next.t('ownerRole'),
  ADMIN: i18next.t('adminRole'),
  MANAGER: i18next.t('managerRole'),
  RECRUITER: i18next.t('recruiterRole'),
  HR: i18next.t('HRRole'),
  SALES: i18next.t('salesRole'),
  EMPLOYEE: i18next.t('employeeRole'),
  CONTRACTOR: i18next.t('contractorRole'),
  PARTNER: i18next.t('partnerRole'),
  CANDIDATE: i18next.t('candidateRole'),
  ACCOUNTANT: i18next.t('accountantRole'),
  ALL: i18next.t('allRoles'),
});

export const rolesList = [
  'OWNER',
  'ADMIN',
  'MANAGER',
  'RECRUITER',
  'HR',
  'SALES',
  'EMPLOYEE',
  'CONTRACTOR',
  'PARTNER',
  'CANDIDATE',
  'ACCOUNTANT',
];

export const rolesListForChanging = [
  'OWNER',
  'ADMIN',
  'MANAGER',
  'RECRUITER',
  'HR',
  'SALES',
  'EMPLOYEE',
  'CONTRACTOR',
  'PARTNER',
  'ACCOUNTANT',
];

export const rolesFilterLabel = () => ({
  ADMIN: i18next.t('adminRole'),
  MANAGER: i18next.t('managerRole'),
  RECRUITER: i18next.t('recruiterRole'),
  HR: i18next.t('HRRole'),
  SALES: i18next.t('salesRole'),
  EMPLOYEE: i18next.t('employeeRole'),
  CONTRACTOR: i18next.t('contractorRole'),
  ACCOUNTANT: i18next.t('accountantRole'),
});

export const filterRoles = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  RECRUITER: 'RECRUITER',
  HR: 'HR',
  SALES: 'SALES',
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
  ACCOUNTANT: 'ACCOUNTANT',
};

export const rolesFilterLabelAll = () => ({
  OWNER: i18next.t('ownerRole'),
  ADMIN: i18next.t('adminRole'),
  MANAGER: i18next.t('managerRole'),
  RECRUITER: i18next.t('recruiterRole'),
  HR: i18next.t('HRRole'),
  SALES: i18next.t('salesRole'),
  EMPLOYEE: i18next.t('employeeRole'),
  CONTRACTOR: i18next.t('contractorRole'),
  PARTNER: i18next.t('partnerRole'),
  CANDIDATE: i18next.t('candidateRole'),
  ACCOUNTANT: i18next.t('accountantRole'),
});

export const filterStatus = {
  NEW_REQUEST: 'NEW_REQUEST',
  PRE_SCREENING: 'PRE_SCREENING',
  UNDER_CONSIDERATION_OF_LEADS: 'UNDER_CONSIDERATION_OF_LEADS',
  APPROVED_BY_LEAD: 'APPROVED_BY_LEAD',
  TEST: 'TEST',
  TECH_INTERVIEW: 'TECH_INTERVIEW',
  SUITABLE: 'SUITABLE',
  SENT_OFFER: 'SENT_OFFER',
  ACCEPTED_OFFER: 'ACCEPTED_OFFER',
  NOT_SUITABLE: 'NOT_SUITABLE',
  DONE: 'DONE',
};

export const statusFilterLabelAll = () => ({
  NEW_REQUEST: i18next.t('newStatus'),
  PRE_SCREENING: i18next.t('preScreeningStatus'),
  UNDER_CONSIDERATION_OF_LEADS: i18next.t('onPendingStatus'),
  APPROVED_BY_LEAD: i18next.t('approvedByLeadStatus'),
  TEST: i18next.t('testStatus'),
  TECH_INTERVIEW: i18next.t('techInterviewStatus'),
  SUITABLE: i18next.t('suitableStatus'),
  SENT_OFFER: i18next.t('sentOfferStatus'),
  ACCEPTED_OFFER: i18next.t('acceptedOfferStatus'),
  NOT_SUITABLE: i18next.t('notSuitableStatus'),
  DONE: i18next.t('doneStatus'),
});

export const filterAllTeam = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  RECRUITER: 'RECRUITER',
  HR: 'HR',
  SALES: 'SALES',
  EMPLOYEE: 'EMPLOYEE',
  CONTRACTOR: 'CONTRACTOR',
  PARTNER: 'PARTNER',
  ACCOUNTANT: 'ACCOUNTANT',
};

export const billabilityReportRoles = [['ADMIN', 'EMPLOYEE', 'MANAGER'], ['CONTRACTOR']];
export const defaultBillabilityReportRoles = ['ADMIN', 'EMPLOYEE', 'MANAGER'];

export const benchReportRoles = [['ADMIN', 'EMPLOYEE', 'MANAGER'], ['CONTRACTOR']];
export const defaultBenchReportRoles = ['ADMIN', 'EMPLOYEE', 'MANAGER'];

export const absenceRequestsStatistics = ['ALL', 'EMPLOYEE', 'CONTRACTOR'];
