import { createAction } from '@reduxjs/toolkit';
import { ApiService } from '../middleware/api';

export const GET_TASKS_HISTORY_BY_ID_PENDING = createAction('projectTasks/GET_TASKS_HISTORY_BY_ID.PENDING');
export const GET_TASKS_HISTORY_BY_ID_SUCCESS = createAction('projectTasks/GET_TASKS_HISTORY_BY_ID.SUCCESS');
export const fetchHistoryByTaskId = (taskId, offset) => async (dispatch) => {
  dispatch(GET_TASKS_HISTORY_BY_ID_PENDING());
  try {
    const { data } = await ApiService.apiCall({
      endpoint: `task-history/task/${taskId}?offset=${offset}`,
      method: 'GET',
    });
    dispatch(GET_TASKS_HISTORY_BY_ID_SUCCESS(data));
  } catch (err) {
  }
};