import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRole, getUserAdditionRole } from '../../Redux/selectors/auth';
import { RolePermissions } from '../../Helpers/RolePermissions';

function PrivateRoute({ component: Component, path, ...rest }) {
  const userRole = useSelector((state) => getUserRole(state));
  const userAdditionRole = useSelector(getUserAdditionRole);
  const [startRoute] = RolePermissions.canAccessRoutes(userRole, userAdditionRole);

  const hasAccessToRoute = () => {
    const rolePaths = RolePermissions.canAccessRoutes(userRole, userAdditionRole);
    if (Array.isArray(path)) {
      return path.find((path) => rolePaths.includes(path));
    } else {
      return rolePaths.includes(path);
    }
  };
  return hasAccessToRoute() || !userRole ? <Route {...rest} component={Component} /> : <Redirect to={startRoute} />;
}

export default PrivateRoute;
