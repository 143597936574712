import authSaga from './Authorization';
import usersSaga from './Users';
import projectsSaga from './Projects';
import projectTaskSaga from './ProjectTasks';
import milestonesSaga from './Milestones';
import timeLogsSaga from './TimeLogs';
import statisticsSaga from './Statistics';
import penaltiesSaga from './Penalties';
import holidaysSaga from './Holidays';
import meetingReportsSaga from './MeetingReports';
import docBooksSaga from './Docbooks';
import docPagesSaga from './Docpages';
import vacationsSaga from './Vacations';
import userCommentsSaga from './UserComments';
import projectCommentsSaga from './ProjectComments';
import projectColumnsSaga from './ProjectColumns';
import userReviewsSaga from './UserReviews';
import userRemindersSaga from './UserReminders';
import vacanciesSaga from './Vacancies';
import specializationsSaga from './Specializations';
import candidateChannelSaga from './CandidateChannel';
import sprintsSaga from './Sprints';
import versionsSaga from './Versions';

const fn = ({ run }) => {
  run(authSaga);
  run(usersSaga);
  run(projectsSaga);
  run(projectTaskSaga);
  run(milestonesSaga);
  run(timeLogsSaga);
  run(statisticsSaga);
  run(penaltiesSaga);
  run(holidaysSaga);
  run(meetingReportsSaga);
  run(docBooksSaga);
  run(docPagesSaga);
  run(vacationsSaga);
  run(userCommentsSaga);
  run(projectCommentsSaga);
  run(projectColumnsSaga);
  run(userReviewsSaga);
  run(userRemindersSaga);
  run(vacanciesSaga);
  run(specializationsSaga);
  run(candidateChannelSaga);
  run(sprintsSaga);
  run(versionsSaga);
};

export default fn;
