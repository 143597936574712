import { fetchAllProjectTasks } from 'Redux/actions/projectTasks';
import { store } from '../../../index';

function* onDeleteProjectTask(action) {
  const { dispatch, getState } = store;
  const { projectId } = action.payload;
  const projectTasksFilters = getState().filters.projectFilters.board;

  dispatch(fetchAllProjectTasks(projectId, { ...projectTasksFilters }));

  yield true;
}

export default onDeleteProjectTask;
