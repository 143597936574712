import { createReducer } from '@reduxjs/toolkit';
import { LOADING } from '../../assets/loading';
import {
  ADD_DOCUMENT_FAILURE,
  CHANGE_CANDIDATE_STATUS_FAILURE,
  CHANGE_CANDIDATE_STATUS_SUCCESS,
  CHANGE_USER_INFO_FAILURE,
  CHANGE_USER_INFO_SUCCESS,
  CHANGE_USER_ROLE_FAILURE,
  CHANGE_USER_ROLE_SUCCESS,
  CHANGE_USER_STATUS_FAILURE,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_SUCCESS_MESSAGE,
  CHANGE_USERT_PARAMETER,
  CLEAR_CHANGE_USER_ERROR,
  CLEAR_USERS,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_SUCCESS,
  GET_ACTIVE_USER_ID_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_RECRUITERS_SUCCESS,
  GET_USER_BY_ID_SUCCESS,
  GET_USERS_BIRTHDAYS_SUCCESS,
  GET_USERS_BY_VACANCY_ID_SUCCESS,
  GET_USERS_STATISTICS_SUCCESS,
  INCREASE_USERS_LENGTH,
  GET_WORK_ANNIVERSARY,
  GET_ALL_USERS_PENDING,
  GET_ALL_USERS_FAILURE,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_PENDING,
} from '../actions/users';

const initialState = {
  selectedUser: null,
  users: [],
  usersRecruiters: [],
  error: null,
  errorChangeUser: null,
  successMessage: '',
  totalLength: null,
  vacancyUsers: [],
  workAnniversary: [],
  loading: LOADING.FAILURE,
};

let prevUsers;

const handlers = {
  [GET_ALL_USERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      users: payload.users,
      totalLength: payload.totalLength,
    };
  },
  [GET_ALL_USERS_PENDING]: (prevState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
  [GET_ALL_USERS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
      error: payload.message,
    };
  },
  [GET_RECRUITERS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      usersRecruiters: payload.users,
    };
  },
  [GET_ACTIVE_USER_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      activeUser: payload.id,
    };
  },
  [CREATE_USER_SUCCESS]: (prevState, { payload }) => {
    prevUsers = [...prevState?.users];
    if (prevUsers.length >= 10) {
      prevUsers.splice(prevUsers.length - 1, 1);
    }
    return {
      ...prevState,
      users: [payload, ...prevUsers],
    };
  },
  [CREATE_USER_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [CHANGE_USER_STATUS_SUCCESS]: (prevState, { payload }) => {
    prevUsers = [...prevState.users];
    const userIndex = prevUsers.findIndex((user) => user._id === payload._id);
    prevUsers[userIndex] = payload;
    return {
      ...prevState,
      users: prevUsers,
    };
  },
  [CHANGE_USER_STATUS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },
  [CHANGE_USERT_PARAMETER]: (prevState, { payload }) => {
    let usersLocal;
    const prevUsers = [...prevState.users];

    if (payload?.value === 'DEACTIVATED' && payload?.parametr === 'status') {
      usersLocal = prevUsers?.filter((el) => el.id !== payload?.id);
    } else {
      usersLocal = prevUsers?.map((el) => {
        if (el?.id === payload?.id) {
          return { ...el, [payload?.parametr]: payload?.value };
        }
        return el;
      });
    }

    return {
      ...prevState,
      users: usersLocal,
      error: 'delete',
    };
  },
  [CHANGE_USER_ROLE_SUCCESS]: (prevState, { payload, successMessage }) => {
    const prevUsers = [...prevState.users];
    const prevSelectedUser = { ...prevState.selectedUser };

    return {
      ...prevState,
      successMessage: successMessage,
      users: prevUsers.filter((user) => user._id !== payload._id),
      selectedUser: prevSelectedUser?._id === payload._id ? payload : prevState.selectedUser,
    };
  },
  [CHANGE_USER_ROLE_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [CHANGE_CANDIDATE_STATUS_SUCCESS]: (prevState, { payload }) => {
    const prevUsers = [...prevState.users];
    const prevSelectedUser = { ...prevState.selectedUser };
    const replacedUsers = prevUsers.map((user) => {
      if (user._id === payload._id) {
        user = payload;
      }
      return user;
    });
    return {
      ...prevState,
      users: replacedUsers,
      selectedUser: prevSelectedUser?._id === payload._id ? payload : prevState.selectedUser,
    };
  },
  [CHANGE_CANDIDATE_STATUS_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },

  [INCREASE_USERS_LENGTH]: (prevState, { payload }) => {
    return {
      ...prevState,
      totalLength: prevState.totalLength + 1,
    };
  },

  [CHANGE_USER_INFO_SUCCESS]: (prevState, { payload }) => {
    const users = [...prevState.users];
    const selectedUser = { ...prevState.selectedUser };
    const userIndex = users.findIndex((user) => user._id === payload._id);
    users.splice(userIndex, 1, payload);
    const newSelectedUser = selectedUser?._id === payload._id ? payload : selectedUser;

    const newState = {
      ...prevState,
      users,
      selectedUser: newSelectedUser,
    };
    return newState;
  },

  [CHANGE_USER_INFO_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      errorChangeUser: payload.response.data.error,
    };
  },

  [GET_USERS_STATISTICS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      usersStatistics: payload,
    };
  },
  [GET_USERS_BIRTHDAYS_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      usersBirthdays: payload,
    };
  },
  [GET_WORK_ANNIVERSARY]: (prevState, { payload }) => {
    return {
      ...prevState,
      workAnniversary: payload,
    };
  },
  [CHANGE_USER_SUCCESS_MESSAGE]: (prevState, { payload }) => {
    return {
      ...prevState,
      successMessage: payload,
      error: null,
    };
  },

  [CLEAR_CHANGE_USER_ERROR]: (prevState, { payload }) => {
    return {
      ...prevState,
      errorChangeUser: null,
    };
  },

  [GET_USER_BY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      selectedUser: payload[0],
    };
  },
  [GET_USER_BY_ID_PENDING]: (prevState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING
    }
  },
  [GET_USER_BY_ID_FAILURE]: (prevState, {payload}) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
      error: payload.message
    }
  },
  [GET_USERS_BY_VACANCY_ID_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
      vacancyUsers: payload,
    };
  },

  [CLEAR_USERS]: (prevState, { payload }) => {
    return {
      ...prevState,
      users: payload,
    };
  },
  [ADD_DOCUMENT_FAILURE]: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload.message,
    };
  },
  [DELETE_DOCUMENT_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },
  [EDIT_DOCUMENT_SUCCESS]: (prevState, { payload }) => {
    return {
      ...prevState,
    };
  },
};

export default createReducer(initialState, handlers);
